/* src/css/Updates.css */
.updates-container {
  background-color: #1a202c;
  color: #e2e8f0;
  min-height: 100vh;
  padding: 20px;
  transition: background-color 0.3s ease;
}

.updates-content {
  max-width: 1800px;
  margin: 0 auto;
}

.filters-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
  align-items: flex-end;
}

.filter-item {
  display: flex;
  flex-direction: column;
}

.filter-item label {
  margin-bottom: 5px;
  font-weight: bold;
}

.filter-item input,
.filter-item select {
  padding: 8px;
  border: 1px solid #4a5568;
  border-radius: 4px;
  background-color: #2d3748;
  color: #e2e8f0;
  transition: all 0.3s ease;
}

.filter-buttons {
  display: flex;
  gap: 10px;
}

.filter-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #48bb78;
  color: #1a202c;
  transition: background-color 0.2s ease;
}

.filter-buttons button:hover {
  background-color: #38a169;
}

.updates-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.updates-table th,
.updates-table td {
  padding: 12px;
  border-bottom: 1px solid #4a5568;
  text-align: left;
  transition: all 0.3s ease;
}

.images-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.update-image-thumb {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #4a5568;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.update-image-thumb:hover {
  transform: scale(1.05);
  opacity: 0.9;
}

.no-updates {
  margin-top: 20px;
  text-align: center;
  font-style: italic;
}

/* Modal styles with custom fade and scale animation */
.image-modal {
  border: 4px solid #ffffff;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: #1a202c;
  padding: 20px;
  border: none;
  border-radius: 8px;
  outline: none;
  max-width: 90%;
  max-height: 90%;
  animation: fadeInScale 0.6s ease-out;
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.image-modal-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  transition: background-color 0.3s ease;
}

.modal-close-button {
  background: #48bb78;
  color: #1a202c;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.modal-close-button:hover {
  background: #38a169;
}

.slider-image-container {
  text-align: center;
  
}

.modal-image {
  max-width: 100%;
  max-height: 70vh;
  margin: 0 auto;
  display: block;
  transition: transform 0.3s ease;
  
}

.modal-image:hover {
  transform: scale(1.02);
}
