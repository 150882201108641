/* Login.css - Dark Theme to match ArtistDashboard/AdminDashboard */

/* 
  The outer container covers the full viewport height 
  and uses a dark background.
*/
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #1a202c; /* Dark background */
  color: #e2e8f0;            /* Light text color */
}

/* 
  The login form panel:
  - Dark background (#2d3748)
  - Padding, rounded corners, subtle shadow
*/
.login-content {
  background: #2d3748;
  color: #e2e8f0;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
}

/* 
  Header text inside the form 
  - Larger font size
  - Light text color
  - Centered
*/
.login-header {
  margin-bottom: 20px;
  text-align: center;
  color: #e2e8f0;
  font-size: 24px;
}

/* The login form itself: stacked vertically */
.login-form {
  display: flex;
  flex-direction: column;
}

/* Input group spacing */
.input-group {
  margin-bottom: 15px;
}

/* Label for inputs */
.input-label {
  display: block;
  margin-bottom: 5px;
  color: #a0aec0; /* Slightly lighter than #e2e8f0 for distinction */
}

/* 
  Input fields:
  - Dark background (#1a202c)
  - Subtle border (#4a5568)
  - Light text (#e2e8f0)
*/
.input-field {
  width: 100%;
  padding: 10px;
  background-color: #1a202c;
  border: 1px solid #4a5568;
  border-radius: 4px;
  box-sizing: border-box;
  color: #e2e8f0;
  transition: border-color 0.2s ease;
}

/* Highlight border on focus */
.input-field:focus {
  outline: none;
  border-color: #48bb78; /* Accent green on focus */
}

/* 
  The login (Sign in) button:
  - Green accent (#48bb78)
  - Dark text (#1a202c) for contrast
  - Slight hover effect
*/
.login-button {
  background-color: #48bb78;
  color: #1a202c;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s, transform 0.2s;
}

/* Hover effect: darker green + slight upward movement */
.login-button:hover {
  background-color: #38a169;
  transform: translateY(-1px);
}
