/* ArtistDashboard.css - Dark Theme + colgroup widths + text wrapping */

/* 1) Overall container */
.artist-dashboard-container {
  background-color: #1a202c;
  /* Dark background */
  color: #e2e8f0;
  /* Light text color */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
}

/* 2) Wrapper for search + table area */
.artist-search-and-table-container {
  width: 100%;
  max-width: 1800px;
  /* Constrain width on large screens */
  margin: 0 auto;
  /* Center horizontally */
  padding: 20px;
  box-sizing: border-box;
}

/* 3) Search input styling */
.artist-search-input {
  width: 100%;
  margin-bottom: 20px;
  background-color: #1a202c;
  border: 1px solid #4a5568;
  border-radius: 4px;
  color: #cbd5e0;
  padding: 10px;
  box-sizing: border-box;
  transition: border-color 0.2s ease;
}

.artist-search-input:focus {
  outline: none;
  border-color: #48bb78;
  /* Green accent on focus */
}

/* 4) Table container: dark panel with some padding and shadow */
.artist-table-container {
  width: 100%;
  background-color: #2d3748;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  overflow-x: auto;
  /* Horizontal scroll if table is too wide */
}

/* 5) The table itself, with colgroup + fixed layout */
.artist-projects-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  /* respect colgroup widths */
  color: #cbd5e0;
}

/* -- Define column widths via colgroup nth-child if needed: 
   (7 columns total) 
   #1: 15%, #2: 15%, #3: 30%, #4: 10%, #5: 10%, #6: 10%, #7: 10%
*/
.artist-projects-table colgroup col:nth-child(1) {
  width: 15%;
}

.artist-projects-table colgroup col:nth-child(2) {
  width: 15%;
}

.artist-projects-table colgroup col:nth-child(3) {
  width: 30%;
}

.artist-projects-table colgroup col:nth-child(4) {
  width: 10%;
}

.artist-projects-table colgroup col:nth-child(5) {
  width: 10%;
}

.artist-projects-table colgroup col:nth-child(6) {
  width: 10%;
}

.artist-projects-table colgroup col:nth-child(7) {
  width: 10%;
}

/* Table header row styling */
.artist-projects-table thead tr {
  background-color: #2c2f35;
  /* Slightly darker background for header */
}

.artist-projects-table th,
.artist-projects-table td {
  padding: 12px;
  border-bottom: 1px solid #4a5568;
  text-align: left;
  vertical-align: top;

  /* Wrapping text to avoid overflow with long links */
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

/* Table headers text color */
.artist-projects-table th {
  color: #a0aec0;
  font-weight: 600;
}

/* Even row shading + hover effect */
.artist-projects-table tbody tr:nth-child(even) {
  background-color: #333a48;
}

.artist-projects-table tbody tr:hover {
  background-color: #3a4151;
}

/* 6) Completion input + button styling */
.artist-completion-input {
  width: 60px;
  background-color: #1a202c;
  border: 1px solid #4a5568;
  border-radius: 4px;
  padding: 5px;
  color: #cbd5e0;
  margin-right: 8px;
  box-sizing: border-box;
}

.artist-update-button {
  background-color: #48bb78;
  /* Green accent */
  color: #1a202c;
  border: none;
  border-radius: 4px;
  padding: 6px 10px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s, transform 0.2s;
}

.artist-update-button:hover {
  background-color: #38a169;
  transform: translateY(-1px);
}

/* 7) Comments list styling inside "Description" */
.artist-comments-list {
  margin: 10px 0 0;
  padding-left: 0;
  list-style: none;
}

.artist-comment-item {
  margin-top: 10px;
}

/* 8) "Card style" layout only on smaller screens (e.g. < 900px).
   If you don't want the table to ever "stack," remove this block. */
@media screen and (max-width: 900px) {

  .artist-projects-table,
  .artist-projects-table thead,
  .artist-projects-table tbody,
  .artist-projects-table th,
  .artist-projects-table td,
  .artist-projects-table tr {
    display: block;
  }

  .artist-projects-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .artist-projects-table tr {
    border: 2px solid #4a5568;
    margin-bottom: 10px;
  }

  .artist-projects-table td {
    border: none;
    border-bottom: 1px solid #4a5568;
    position: relative;
    padding-left: 50%;
    text-align: right;
  }

  .artist-projects-table td::before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
    content: attr(data-label);
    color: #a0aec0;
  }
}

/* 9) Dropzone: limit the maximum width so it doesn't overlap */
.dropzone {
  width: 100%;
  /* Fill the cell horizontally */
  max-width: 500px;
  /* but never exceed 220px or so */
  background-color: #1a202c;
  border: 2px dashed #4a5568;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  margin: 10px 0;
  color: #cbd5e0;
  text-align: center;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

.dropzone:hover {
  background-color: #2d3748;
  border-color: #48bb78;
}