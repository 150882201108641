/* Attendance.css - Dark Theme with Improved UI/UX */

/* Overall container */
.attendance-container {
  background-color: #1a202c;
  color: #e2e8f0;
  min-height: 100vh;

  width: 100%;
  box-sizing: border-box;
}

/* Section container for attendance and leave sections */
.section {
  max-width: 1000px;
  margin: 20px auto;
  background-color: #2d3748;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
}

/* Headings */
.section h2,
.section h3 {
  text-align: center;
  margin-bottom: 20px;
}

/* Attendance Section */
.attendance-date {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.attendance-date label {
  margin-right: 10px;
  font-size: 16px;
}
.attendance-date input[type="date"] {
  padding: 8px;
  border: 1px solid #4a5568;
  border-radius: 4px;
  background-color: #1a202c;
  color: #e2e8f0;
}

/* Button for marking attendance */
.attendance-mark-btn {
  display: block;
  margin: 0 auto 20px;
  padding: 12px 20px;
  background-color: #48bb78;
  color: #1a202c;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s, transform 0.2s;
}
.attendance-mark-btn:hover {
  background-color: #38a169;
  transform: translateY(-2px);
}

/* Marked info message */
.marked-info {
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

/* Filter container for attendance */
.filter-container {
  text-align: center;
  margin-bottom: 20px;
}
.filter-container label {
  margin-right: 10px;
  font-size: 16px;
}
.filter-container select {
  padding: 8px;
  border: 1px solid #4a5568;
  border-radius: 4px;
  background-color: #1a202c;
  color: #e2e8f0;
  font-size: 14px;
}

/* Table styling */
.attendance-table,
.summary-table,
.leave-applications-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  table-layout: auto;
  color: #cbd5e0;
}
.attendance-table th,
.attendance-table td,
.summary-table th,
.summary-table td,
.leave-applications-table th,
.leave-applications-table td {
  padding: 12px;
  border: 1px solid #4a5568;
  text-align: left;
}
.attendance-table th,
.summary-table th,
.leave-applications-table th {
  background-color: #2c2f35;
  font-weight: 600;
}
.attendance-table tbody tr:nth-child(even),
.summary-table tbody tr:nth-child(even),
.leave-applications-table tbody tr:nth-child(even) {
  background-color: #333a48;
}
.attendance-table tbody tr:hover,
.summary-table tbody tr:hover,
.leave-applications-table tbody tr:hover {
  background-color: #3a4151;
}
.chart-container {
  height: 300px;  /* Adjust this value as needed */
  position: relative;
}


/* Leave Management Section */
.month-selector {
  text-align: center;
  margin-bottom: 20px;
}
.month-selector label {
  margin-right: 10px;
  font-size: 16px;
}
.month-selector input[type="month"] {
  padding: 8px;
  border: 1px solid #4a5568;
  border-radius: 4px;
  background-color: #1a202c;
  color: #e2e8f0;
}

/* Leave Application Form */
.leave-application-form {
  margin: 20px auto;
  max-width: 600px;
  padding: 20px;
  background-color: #1a202c;
  border: 1px solid #4a5568;
  border-radius: 8px;
}
.leave-application-form .leave-type-selection {
  text-align: center;
  margin-bottom: 20px;
}
.leave-application-form .leave-type-selection label {
  margin-right: 20px;
  font-size: 16px;
}
.leave-application-form label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
}
.leave-application-form input[type="date"],
.leave-application-form select,
.leave-application-form textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #4a5568;
  border-radius: 4px;
  background-color: #2d3748;
  color: #e2e8f0;
  box-sizing: border-box;
}
.leave-application-form textarea {
  resize: vertical;
  min-height: 80px;
}
.apply-leave-btn {
  display: block;
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  background-color: #48bb78;
  color: #1a202c;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;
}
.apply-leave-btn:hover {
  background-color: #38a169;
}

/* Divider between sections */
.divider {
  border: none;
  border-top: 1px solid #4a5568;
  margin: 40px 0;
}
