/* HolidayCalendar.css */

/* Import a modern font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Global Styles */
html, body {
  background-color: #1a202c;
  margin: 0;
  padding: 0;
  height: 100%;
}



.holiday-calendar-container {
  background: #1a202c;
  /* Dark slate background */
  color: #edf2f4;
  /* Off-white text */
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  padding-bottom: 20px; /* Added bottom padding to create space */

}

.holiday-calendar-container h2 {
  text-align: center;
  margin: 50px 0 50px;
  color: #edf2f4;
}

.holiday-calendar-container h3 {
  text-align: center;
  margin: 10px 0 20px;
  color: #edf2f4;
}

/* Calendar Container */
.calendar-wrapper {
  margin: 0 auto 40px;
  max-width: 600px;
  background: linear-gradient(145deg, #8d99ae, #edf2f4);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: relative;
}

/* Override react-calendar default styles */
.react-calendar {
  width: 100%;
  border: none;
  font-family: inherit;
  background: transparent;
  color: #2b2d42;
}

.react-calendar__navigation {
  background: #edf2f4;
  border-bottom: 1px solid #8d99ae;
  padding: 10px;
}

.react-calendar__navigation button {
  background: none;
  border: none;
  font-size: 1.1em;
  font-weight: 500;
  color: #2b2d42;
  transition: color 0.3s ease;
}

.react-calendar__navigation button:hover {
  color: #ef233c;
}

.react-calendar__month-view__weekdays {
  background: #edf2f4;
  text-align: center;
  padding: 8px 0;
  border-bottom: 1px solid #8d99ae;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  font-weight: 500;
  color: #2b2d42;
}

.react-calendar__tile {
  max-width: initial !important;
  padding: 10px 6px;
  background: #8d99ae;
  margin: 1px;
  border: none;
  color: #2b2d42;
  font-weight: 500;
  transition: background 0.3s ease, transform 0.2s ease;
  position: relative;
}

.react-calendar__tile:hover {
  background: #edf2f4;
  transform: scale(1.05);
}

.react-calendar__tile--active {
  color: #edf2f4 !important;
}

/* Holiday marker on calendar tiles */
.holiday-marker {
  position: absolute;
  bottom: 4px;
  right: 4px;
  font-size: 1.2em;
}

/* Delete Button Styles */
.delete-button {
  background-color: #ef233c;
  /* A vibrant red */
  color: #edf2f4;
  /* Off-white text for contrast */
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.delete-button:hover {
  background-color: #d90429;
  transform: scale(1.05);
}

.delete-button:active {
  transform: scale(0.98);
}


/* Holiday List Card */
.holiday-info {
  margin: 0 auto 40px;
  max-width: 600px;
  background: #8d99ae;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.holiday-info ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.holiday-info li {
  margin: 8px 0;
  font-size: 1em;
}

.holiday-info p {
  text-align: center;
  font-size: 1.1em;
  margin-top: 10px;
}



/* Add Holiday Form Card */
.add-holiday-form {
  margin: 0 auto 40px;
  max-width: 600px;
  background: #edf2f4;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  gap: 20px; /* Provides consistent vertical spacing between form elements */
}

.add-holiday-form h3 {
  color: #2b2d42;
  margin-bottom: 20px;
  text-align: center;
}

.add-holiday-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.add-holiday-form label {
  font-size: 1em;
  color: #2b2d42;
  display: block;
  font-size: 1em;
  color: #2b2d42;
  margin-bottom: 5px;
}

.add-holiday-form input[type="date"],
.add-holiday-form input[type="text"] {
  width: 100%; /* Ensures the input takes up full width */
  padding: 10px;
  border: 1px solid #8d99ae;
  border-radius: 5px;
  font-size: 1em;
  color: #2b2d42;
  background: #fff;
  outline: none;
  transition: border-color 0.3s ease;
}

.add-holiday-form input[type="date"]:focus,
.add-holiday-form input[type="text"]:focus {
  border-color: #ef233c;
}

.add-holiday-form button {
  padding: 12px;
  background: #ef233c;
  color: #edf2f4;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background 0.3s ease;
}

.add-holiday-form button:hover {
  background: #d90429;
}

/* Import Holiday Form Card */
.import-holiday-form {
  margin: 0 auto 40px;
  max-width: 600px;
  background: #edf2f4;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}

.import-holiday-form h3 {
  color: #2b2d42;
  margin-bottom: 20px;
  text-align: center;
}

.import-holiday-form input[type="file"] {
  padding: 10px;
  border: 1px solid #8d99ae;
  border-radius: 5px;
  font-size: 1em;
  color: #2b2d42;
  background: #fff;
  outline: none;
  transition: border-color 0.3s ease;
  display: block;
  margin: 0 auto 15px;
}

.import-holiday-form input[type="file"]:focus {
  border-color: #ef233c;
}

.import-holiday-form p {
  text-align: center;
  font-size: 0.9em;
  color: #2b2d42;
}