/* ====================
   GLOBAL STYLES
===================== */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.list-page-container {
  background-color: #1a202c;
  color: #e2e8f0;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

.list-page {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

/* ====================
   NAVBAR STYLES
===================== */
.navbar-container {
  background-color: #2d3748;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #e2e8f0;
  margin-bottom: 20px;
}

.navbar-brand {
  font-size: 20px;
  font-weight: bold;
}

.hamburger-menu {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  color: #e2e8f0;
  cursor: pointer;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 15px;
}

.nav-links li a {
  color: #e2e8f0;
  text-decoration: none;
  font-size: 16px;
}

/* Mobile Navbar */
@media (max-width: 768px) {
  .hamburger-menu {
    display: block;
  }

  .nav-links {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 80%;
    background-color: #2d3748;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 0;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    z-index: 1000;
  }

  .nav-links.show {
    transform: translateX(0);
  }

  .nav-link {
    display: block;
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }
}

/* ====================
   ADMIN LIST VIEW STYLES
===================== */
/* Header */
.admin-list h2 {
  text-align: center;
  margin-bottom: 20px;
}

/* User selection area */
.user-selection {
  text-align: center;
  margin-bottom: 20px;
}

.user-selection label {
  margin-right: 10px;
}

/* New Entry Form */
.admin-form {
  background: #2d3748;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.admin-form:hover {
  transform: translateY(-2px);
  background-color: #2c2f35;
}

.admin-form .entry-row {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.admin-form .entry-row input[type="text"],
.admin-form .entry-row select {
  flex: 1;
  min-width: 150px;
  background-color: #1a202c;
  border: 1px solid #4a5568;
  color: #cbd5e0;
  padding: 8px;
  border-radius: 4px;
  transition: border-color 0.2s ease;
}

.admin-form .entry-row input[type="text"]:focus,
.admin-form .entry-row select:focus {
  border-color: #48bb78;
  outline: none;
}

/* Dark theme for dropdowns */
.dark-select {
  background-color: #1a202c;
  border: 1px solid #4a5568;
  color: #cbd5e0;
  padding: 8px;
  border-radius: 4px;
  transition: border-color 0.2s ease;
}

/* Add Row Button */
.add-row-btn {
  background-color: #48bb78;
  color: #1a202c;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 18px;
  margin-top: 10px;
  transition: background-color 0.2s, transform 0.2s;
}

.add-row-btn:hover {
  background-color: #38a169;
  transform: translateY(-1px);
}

/* Submit Button */
.admin-submit-button {
  width: 100%;
  padding: 10px 0;
  background-color: #48bb78;
  color: #1a202c;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s, transform 0.2s;
  margin-top: 10px;
}

.admin-submit-button:hover {
  background-color: #38a169;
  transform: translateY(-1px);
}

/* Filter Section */
.filter-section {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.filter-section label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #a0aec0;
}

.filter-section input,
.filter-section select {
  margin-top: 5px;
  padding: 8px;
  background-color: #1a202c;
  border: 1px solid #4a5568;
  color: #cbd5e0;
  border-radius: 4px;
  transition: border-color 0.2s ease;
}

.filter-section input:focus,
.filter-section select:focus {
  border-color: #48bb78;
  outline: none;
}

.filter-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.filter-buttons button {
  background-color: #48bb78;
  color: #1a202c;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s, transform 0.2s;
}

.filter-buttons button:hover {
  background-color: #38a169;
  transform: translateY(-1px);
}

/* Records Table Wrapper */
.records-table-wrap {
  display: flex;
  justify-content: center;
  overflow-x: auto;
  margin-top: 20px;
}

/* Records Table */
.records-table {
  width: 50%;
  border-collapse: collapse;
  table-layout: fixed;
  background: #2d3748;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  margin-bottom: 100px;
}

.records-table thead {
  background-color: #2c2f35;
}

.records-table thead th {
  padding: 12px;
  color: #a0aec0;
  text-align: left;
  border-bottom: 1px solid #4a5568;
}

.records-table tbody tr:nth-child(even) {
  background-color: #333a48;
}

.records-table tbody tr:hover {
  background-color: #3a4151;
}

.records-table td {
  padding: 12px;
  border-bottom: 1px solid #4a5568;
  word-wrap: break-word;
  white-space: normal;
}

/* Inline Form (for Add More / Editing) */
.inline-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.inline-form input,
.inline-form select {
  background-color: #1a202c;
  border: 1px solid #4a5568;
  color: #cbd5e0;
  padding: 6px 8px;
  margin-right: 5px;
  border-radius: 4px;
  transition: border-color 0.2s ease;
  min-width: 120px;
  flex: 1;
}

.inline-form input:focus,
.inline-form select:focus {
  border-color: #48bb78;
  outline: none;
}

/* Action Buttons */
.action-btn {
  background-color: #48bb78;
  color: #1a202c;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 14px;
  margin: 0 2px;
  transition: background-color 0.2s, transform 0.2s;
}

.action-btn:hover {
  background-color: #38a169;
  transform: translateY(-1px);
}

.save-btn {
  /* Additional styles if needed */
}

.delete-btn {
  background-color: #e53e3e;
  color: #f7fafc;
}

.delete-btn:hover {
  background-color: #c53030;
}

/* For rows that are being edited, hide cell labels */
.editing-row td::before {
  content: "";
}

/* For the "Add More Project" row, remove the header labels */
.add-more-row td::before {
  content: "";
}

/* ====================
   ARTIST LIST VIEW STYLES
===================== */
.artist-list h2 {
  text-align: center;
  margin-bottom: 20px;
}

/* Center the Common Date container */
.common-date {
  text-align: center;
  margin: 20px 0;
}

.common-date input[type="date"].dark-input {
  background-color: #1a202c;
  border: 1px solid #4a5568;
  color: #cbd5e0;
  padding: 8px;
  border-radius: 4px;
  text-align: center;
}

.common-date label {
  color: #cbd5e0;
  font-size: 16px;
  margin-right: 10px;
}

/* ====================
   NOTIFICATION PANEL STYLES
===================== */
.notification-panel {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  background-color: rgba(45, 55, 72, 0.95);
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  color: #e2e8f0;
  z-index: 2000;
  font-family: Arial, sans-serif;
  transition: all 0.3s ease;
}

/* When minimized, only show header */
.notification-panel.minimized {
  height: 40px;
  overflow: hidden;
  padding: 10px 15px;
}

/* Header with toggle button */
.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-header h3 {
  margin: 0;
  font-size: 18px;
}

.toggle-btn {
  background: transparent;
  border: none;
  color: #e2e8f0;
  font-size: 20px;
  cursor: pointer;
}

/* Notification list and items */
.notification-panel ul {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 300px;
  overflow-y: auto;
}

.notification-item {
  background-color: #2d3748;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification-item span {
  flex: 1;
  font-size: 14px;
  margin-right: 8px;
  word-break: break-word;
}

.clear-all-btn {
  background-color: #48bb78;
  color: #1a202c;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  margin-bottom: 10px;
  transition: background-color 0.2s ease;
}

.clear-all-btn:hover {
  background-color: #38a169;
}

.remove-note-btn {
  background: transparent;
  border: none;
  color: #e53e3e;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.2s ease;
}

.remove-note-btn:hover {
  color: #c53030;
}

.spacer-div {
  height: 2px;
  background: linear-gradient(90deg,
      #38a169,
      #2f855a,
      #38a169);
  background-size: 200% 100%;
  animation: wave 3s linear infinite;
  box-shadow: 0 0 10px 2px rgba(56, 161, 105, 0.6);
  border: none;
}

/* Keyframes for the wave animation */
@keyframes wave {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}




/* ====================
   RESPONSIVE ADJUSTMENTS
===================== */
@media screen and (max-width: 768px) {
  .records-table thead {
    display: none;
  }

  .records-table,
  .records-table tbody,
  .records-table tr,
  .records-table td {
    display: block;
    width: 100%;
  }

  .records-table tr {
    margin-bottom: 15px;
    border: 1px solid #4a5568;
    border-radius: 8px;
    padding: 10px;
  }

  .records-table td {
    position: relative;
    padding-left: 50%;
    text-align: left;
    border: none;
    border-bottom: 1px solid #4a5568;
    margin-bottom: 10px;
  }

  .records-table tr:last-child td {
    border-bottom: none;
  }

  .records-table td::before {
    position: absolute;
    top: 0;
    left: 10px;
    width: 40%;
    padding-right: 10px;
    font-weight: bold;
    white-space: nowrap;
  }

  .records-table tr:not(.sub-row):not(.add-more-row) td:nth-of-type(1)::before {
    content: "Date:";
  }

  .records-table tr:not(.sub-row):not(.add-more-row) td:nth-of-type(2)::before {
    content: "Artist:";
  }

  .records-table tr:not(.sub-row):not(.add-more-row) td:nth-of-type(3)::before {
    content: "Project Name:";
  }

  .records-table tr:not(.sub-row):not(.add-more-row) td:nth-of-type(4)::before {
    content: "Project Type:";
  }

  .records-table tr:not(.sub-row):not(.add-more-row) td:nth-of-type(5)::before {
    content: "Note:";
  }

  .records-table tr:not(.sub-row):not(.add-more-row) td:nth-of-type(6)::before {
    content: "Action:";
  }

  .records-table tr.sub-row td::before,
  .records-table tr.add-more-row td::before {
    content: "";
  }
}

@media screen and (max-width: 480px) {

  /* Keep notification panel in bottom-right with an upward offset */
  .notification-panel {
    position: fixed;
    right: 5%;
    width: 90%;
    max-width: 320px;
    padding: 10px;
    font-size: 14px;
    z-index: 1;

  }

  .notification-panel h3 {
    font-size: 16px;
  }

  .notification-item {
    padding: 6px;
    font-size: 13px;
  }

  .clear-all-btn {
    padding: 6px 8px;
    font-size: 13px;
  }

  .remove-note-btn {
    font-size: 14px;
  }

  .toggle-btn {
    font-size: 18px;
  }
}