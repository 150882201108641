/* AnnualReport.css - Dark Theme */

/* Overall container for the Annual Report page */
.annual-report-container {
  background-color: #1a202c; /* Dark background */
  color: #e2e8f0;           /* Light text */
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
}

/* 1. Selection controls (Year/Month dropdowns) */
.annual-selection-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  align-items: center;
}

.annual-selection-select {
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #4a5568;
  background-color: #1a202c; /* Dark background */
  color: #e2e8f0;            /* Light text */
  font-size: 14px;
  transition: border-color 0.2s ease;
}

.annual-selection-select:focus {
  outline: none;
  border-color: #48bb78; /* Accent green */
}

/* 2. Chart container */
.annual-chart-container {
  width: 100%;
  max-width: 1000px;
  height: 500px;
  padding: 20px;
  background-color: #2d3748; /* Dark panel background */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  margin: 20px auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 3. Table container */
.sheet-table-container,
.annual-table-container {
  overflow-x: auto;
  margin-top: 20px;
  background-color: #2d3748;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
}

/* 4. Annual table styling */
.annual-table {
  width: 100%;
  border-collapse: collapse;
  /* If using colgroup in your JSX, table-layout can remain fixed */
  table-layout: fixed;
  color: #ffffff;
}

/* Define column widths via colgroup in your JSX;
   (This CSS is optional if you already define them via a <colgroup> in the component) */

/* 5. Table header and cell styling */
.annual-table thead tr {
  background-color: #1a202c; /* Slightly darker header */
}

.annual-table th,
.annual-table td {
  padding: 12px;
  border: 1px solid #ffffff; /* Dark border */
  text-align: left;
  vertical-align: top;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

.annual-table th {
  color: #ffffff;
  font-weight: 600;
}

/* Zebra striping for rows in a dark theme */
.annual-table tbody tr:nth-child(odd) {
  background-color: #1a202c;
}
.annual-table tbody tr:nth-child(even) {
  background-color: #1a202c;
}

.annual-table tbody tr:hover {
  background-color: #3a4151;
}

/* 6. Total amount display */
.annual-total-amount {
  text-align: right;
  font-weight: bold;
  margin-top: 20px;
  padding: 12px;
  background-color: #2d3748;
  border-radius: 4px;
  color: #e2e8f0;
  margin-right: 20px;
}

/* 7. Inline editing & buttons within table cells */
.annual-table td .inline-edit-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.annual-table td .btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

/* Common button styles */
.annual-table .btn {
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease;
}

/* Specific button styles (adjust colors as needed) */
.annual-table .btn-edit {
  background-color: #f0ad4e;
  color: #1a202c;
}

.annual-table .btn-save {
  background-color: #5cb85c;
  color: #1a202c;
}

.annual-table .btn-cancel {
  background-color: #d9534f;
  color: #1a202c;
}

.annual-table .btn:hover {
  opacity: 0.8;
}

/* 8. Select elements (Payment Type, Project Status, etc.) */
.payment-type-select,
.custom-select {
  padding: 6px 10px;
  border: 1px solid #4a5568;
  border-radius: 4px;
  background-color: #1a202c;
  color: #e2e8f0;
  cursor: pointer;
}

/* Background variations for select values */
.red-background {
  background-color: #dc3545;
  color: #fff;
}
.green-background {
  background-color: #28a745;
  color: #fff;
}
.grey-background {
  background-color: #6c757d;
  color: #fff;
}
.random-background {
  background-color: #17a2b8;
  color: #fff;
}
.refund-background {
  background-color: #ffcccb;
  color: #000;
}

/* 9. Responsive adjustments (card layout for small screens) */
@media (max-width: 768px) {
  .annual-table,
  .annual-table thead,
  .annual-table tbody,
  .annual-table th,
  .annual-table td,
  .annual-table tr {
    display: block;
  }

  .annual-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .annual-table tr {
    border: 2px solid #4a5568;
    margin-bottom: 10px;
  }

  .annual-table td {
    position: relative;
    padding-left: 50%;
    text-align: right;
    padding-bottom: 10px;
  }

  .annual-table td::before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
    content: attr(data-label);
    color: #a0aec0;
  }
}

/* 10. Responsive adjustments for filter controls on small screens */
@media (max-width: 768px) {
  .annual-selection-select,
  .sheet-filter-btn,
  .clear-filter-btn,
  .export-btn {
    flex-grow: 1;
    margin-bottom: 10px;
  }
}

/* 11. For very small screens, adjust font size and padding */
@media (max-width: 480px) {
  .annual-selection-select,
  .sheet-filter-btn,
  .clear-filter-btn,
  .export-btn {
    font-size: 12px;
    padding: 6px 10px;
  }
}
