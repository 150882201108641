/* Sheet.css - Aligning with the Admin/Artist Theme */
.sheet-container {
  padding: 20px;
  background-color: #1a202c; /* Dark background */
  color: #e2e8f0;           /* Light text color */
  min-height: 100vh;
  box-sizing: border-box;
  width: 100%;
}

.sheet-table-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  margin: 20px; /* Add some margin for aesthetics */
}

.sheet-table {
  width: 100%; /* Ensure the table uses up the available space */
  min-width: 600px; /* Set a minimum width to ensure table integrity */
  border-collapse: collapse; /* Improve border handling */
}

.sheet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.sheet-filter {
  display: flex;
  gap: 10px; /* Ensures space between controls */
  flex-wrap: wrap; /* Allows items to wrap onto the next line as needed */
  align-items: center; /* Aligns items vertically */
  justify-content: start; /* Aligns items to the start of the container */
  padding: 10px 0; /* Adds padding for visual spacing */
}

.sheet-filter select,
.sheet-filter-btn,
.clear-filter-btn,
.export-btn {
  padding: 8px 12px;
  border: 1px solid #4a5568;
  border-radius: 4px;
  background-color: #48bb78; /* Green accent */
  color: #1a202c;            /* Dark text for contrast */
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
  transition: background-color 0.2s ease;
}
.sheet-filter-btn:hover,
.clear-filter-btn:hover,
.export-btn:hover {
  opacity: 0.9; /* Slight opacity change on hover */
}

.sheet-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border: 1px solid #ccc; /* Add a border around the table for completeness */
}

.sheet-table th,
.sheet-table td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #ddd; /* Maintain the bottom border for horizontal lines */
  border-right: 1px solid #ddd; /* Add right border for vertical lines */
  color: rgb(255, 255, 255); /* Text color correction if necessary */
}

.sheet-table th:last-child,
.sheet-table td:last-child {
  border-right: none; /* Remove the right border for the last column */
}

.sheet-table tr:last-child td {
  border-bottom: none; /* Optional: remove the bottom border for the last row */
}

.sheet-table th {
  color: rgb(255, 255, 255); /* Ensure text color is white for header */
}

.sheet-table tr:hover {
  background-color: rgba(221, 221, 221, 0.7); /* Light grey color with transparency */
}

.total-amount-sheet {
  margin-top: 20px;
  padding: 12px;
  background-color: #ffffff;
  border-radius: 4px;
  text-align: right;
  font-weight: bold;
  color: #0056b3; /* Blue color for total amount */
}

.payment-type-select,
.custom-select {
  border: none;
  color: white;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.red-background {
  background-color: #dc3545; /* Red background */
}

.green-background {
  background-color: #28a745; /* Green background */
}

.grey-background {
  background-color: #6c757d; /* Grey background */
}

.random-background {
  background-color: #17a2b8; /* Another color for variety */
}

/* Refund background style */
.refund-background {
  background-color: #ffcccb; /* Light red or any color you prefer for Refund */
  color: black;
}

/* Media Query for devices with max-width of 768px */
@media (max-width: 768px) {
  /* Override the min-width to allow the table to scale on small screens */
  .sheet-table {
    min-width: 100%;
  }

  .sheet-table,
  .sheet-table thead,
  .sheet-table tbody,
  .sheet-table th,
  .sheet-table td,
  .sheet-table tr {
    display: block;
  }

  .sheet-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .sheet-table tr {
    margin-bottom: 10px;
    border-bottom: 2px solid #eee;
  }

  .sheet-table td {
    position: relative;
    padding-left: 1%;
    text-align: left;
    padding-bottom: 10px;
  }

  .sheet-table td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
    content: attr(data-label);
  }
}

/* Media Query for devices with max-width of 768px for filter controls */
@media (max-width: 768px) {
  .sheet-filter select,
  .sheet-filter-btn,
  .clear-filter-btn,
  .export-btn {
    flex-grow: 1; /* Makes each element take equal space */
    margin-bottom: 10px; /* Adds bottom margin for stacked elements */
  }
}

/* Media Query for devices with max-width of 480px - smaller font and padding */
@media (max-width: 480px) {
  .sheet-filter select,
  .sheet-filter-btn,
  .clear-filter-btn,
  .export-btn {
    font-size: 12px;
    padding: 6px 10px;
  }
}
