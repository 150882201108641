/* AdminDashboard.css - Updated for a more centered layout, no horizontal scroll, 
   and improved dark theme styling. */

.admin-dashboard-container {
  background-color: #1a202c;
  /* Dark background */
  color: #e2e8f0;
  /* Light text color */
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  min-height: 100vh;
  /* Ensure it covers full height of the screen */
}

/* 
    .content-wrapper 
    - Centers the form + file upload area 
    - Wraps to multiple rows on smaller screens 
  */
.content-wrapper {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  /* Limit overall container width */
  margin: 0 auto;
  /* Center the container on the page */
  padding: 20px;
  box-sizing: border-box;
}

/* 
    Panels for the form and file-upload area 
  */
.admin-form,
.file-upload-container {
  flex: 1;
  min-width: 300px;
  /* Prevent them from being too narrow */
  max-width: 480px;
  /* Optional limit to keep them from stretching too wide */
  margin: 0 auto;
  /* Center each panel if there's extra space */
  padding: 15px;
  background: #2d3748;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.admin-form:hover,
.file-upload-container:hover {
  transform: translateY(-2px);
  background-color: #2c2f35;
  /* Slightly darker on hover */
}

.dark-select {
  background-color: #1a202c;
  /* Same dark color as your inputs */
  color: #cbd5e0;
  /* Light text */
  border: 1px solid #4a5568;
  /* Matches your theme border */
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  transition: border-color 0.2s ease;
}

.dark-select:focus {
  outline: none;
  border-color: #48bb78;
  /* Your accent color on focus */
}

/* Force options to have a dark background 
     (works in most desktop browsers, not guaranteed on all mobile OS) */
.dark-select option {
  background-color: #2d3748;
  color: #cbd5e0;
}

/* A dark-themed textarea consistent with your existing styling */
.comment-textarea {
  background-color: #1a202c;
  /* Same dark background as other fields */
  color: #cbd5e0;
  /* Light text */
  border: 1px solid #4a5568;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  height: 50px;
  /* or adjust as needed */
  box-sizing: border-box;
  transition: border-color 0.2s ease;
  resize: vertical;
  /* Let user resize vertically if you want */
}

.comment-textarea:focus {
  border-color: #48bb78;
  /* Accent color on focus */
  outline: none;
}

/* Placeholder text color */
.comment-textarea::placeholder {
  color: #718096;
  /* A mid-gray that’s visible on dark background */
}

/* The Submit Comment button styled like your other buttons */
.comment-submit-button {
  background-color: #48bb78;
  color: #1a202c;
  border: none;
  /* If you want a border, add one here */
  border-radius: 4px;
  padding: 8px 12px;
  margin-top: 8px;
  /* Space above the button */
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s, transform 0.2s;
}

/* Button hover effect */
.comment-submit-button:hover {
  background-color: #38a169;
  transform: translateY(-1px);
}


/* Form inputs, textareas, selects */
.input,
.admin-textarea,
.select {
  width: 100%;
  background-color: #1a202c;
  /* same dark bg as your inputs */

  padding: 8px;
  margin-bottom: 8px;
  background-color: #1a202c;
  border: 1px solid #4a5568;
  border-radius: 4px;
  color: #cbd5e0;
  box-sizing: border-box;
  transition: border-color 0.2s ease;
}

.input:focus,
.admin-textarea:focus,
.select:focus {
  border-color: #48bb78;
  /* Accent color on focus */
  outline: none;
}

/* Create Project button */
.admin-submit-button {
  width: 100%;
  padding: 10px 0;
  background-color: #48bb78;
  /* Accent */
  color: #1a202c;
  /* Dark text for contrast */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  transition: background-color 0.2s, transform 0.2s;
}

.admin-submit-button:hover {
  background-color: #38a169;
  transform: translateY(-1px);
}

/* Search input (above the table) */
.search-input {
  margin-bottom: 20px;
  background-color: #1a202c;
  border: 1px solid #4a5568;
  color: #cbd5e0;
}

/* 
    Table wrapper:
    - Centers the table 
    - Prevents horizontal scroll on larger devices
  */
.admin-projects-table-wrap {
  width: 100%;
  max-width: 1800px;
  /* Constrains the table container width */
  margin: 2% auto;
  /* Center on page */
  background: #2d3748;
  /* Dark panel background */
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  overflow-x: auto;
  /* Allows horizontal scroll on smaller screens if needed */
}

/* 
    Table itself:
    - Use table-layout: fixed so columns don't force horizontal scrolling 
  */
.admin-projects-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.admin-projects-table colgroup col:nth-child(1) {
  width: 15%;
  /* Project Name */
}

.admin-projects-table colgroup col:nth-child(2) {
  width: 15%;
  /* Client Email */
}

.admin-projects-table colgroup col:nth-child(3) {
  width: 30%;
  /* Description - bigger chunk */
}

.admin-projects-table colgroup col:nth-child(4) {
  width: 10%;
  /* Assigned To */
}

.admin-projects-table colgroup col:nth-child(5) {
  width: 10%;
  /* Start Date */
}

.admin-projects-table colgroup col:nth-child(6) {
  width: 10%;
  /* End Date */
}

.admin-projects-table colgroup col:nth-child(7) {
  width: 10%;
  /* Completion % */
}

/* Table header & cells */
.admin-projects-table th,
.admin-projects-table td {
  padding: 12px;
  border-bottom: 1px solid #4a5568;
  /* Allow text to wrap onto multiple lines if too long */
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
}

.admin-projects-table th {
  background-color: #2c2f35;
  color: #a0aec0;
  text-align: left;
}

/* Even row shading */
.admin-projects-table tbody tr:nth-child(even) {
  background-color: #333a48;
}

/* Hover row shading */
.admin-projects-table tbody tr:hover {
  background-color: #3a4151;
}

/* Completion input & update button */
.completion-input {
  width: 60px;
  background-color: #1a202c;
  border: 1px solid #4a5568;
  border-radius: 4px;
  padding: 5px;
  color: #cbd5e0;
}

.update-button {
  margin-left: 2%;
  background-color: #48bb78;
  color: #1a202c;
  border: none;
  border-radius: 4px;
  padding: 6px 10px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
}

.update-button:hover {
  background-color: #38a169;
  transform: translateY(-1px);
}

/* Ensures comment textareas don't overflow horizontally */
.admin-projects-table td textarea {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  resize: vertical;
  /* Only allow vertical resizing */
}

/* 
    Mobile-friendly table:
    - Original media query for max-width: 1600px
    - Displays each row as block for narrower screens 
  */
@media screen and (max-width: 1600px) {

  .admin-projects-table,
  .admin-projects-table thead,
  .admin-projects-table tbody,
  .admin-projects-table th,
  .admin-projects-table td,
  .admin-projects-table tr {
    display: block;
  }

  .admin-projects-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .admin-projects-table tr {
    border: 2px solid #4a5568;
    margin-bottom: 10px;
  }

  /* Ensure that links are clickable */
  a {
    pointer-events: auto;
  }



  .admin-projects-table td {
    border: none;
    border-bottom: 1px solid #4a5568;
    position: relative;
    padding-left: 50%;
    text-align: right;
    white-space: normal;
    text-overflow: clip;
  }

  .admin-projects-table td::before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
    content: attr(data-label);
    color: #a0aec0;
  }
}

/* No overflow for table cells on large screens */
.admin-projects-table td {
  word-wrap: break-word;
}