/* Navbar.css - Dark Theme */

/* Navbar container styling */
.navbar-container {
  background-color: #2d3748; /* Dark background */
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

/* Brand styling */
.navbar-brand {
  color: #f7fafc; /* Light text for brand */
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

/* Navigation links list styling */
.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  z-index: 1000;
}

/* Individual link styling */
.nav-link {
  color: #cbd5e0; /* Light link text */
  text-decoration: none;
  font-size: 16px;
  transition: color 0.2s;
}

/* Hover effect for links */
.nav-link:hover {
  color: #e2e8f0;
  border-radius: 4px;
}

/* Hamburger menu button (hidden by default) */
.hamburger-menu {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  color: #f7fafc; /* Match text color */
  cursor: pointer;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  /* Show the hamburger menu on small screens */
  .hamburger-menu {
    display: block;
  }
  
  /* Sidebar styling for mobile view */
  .nav-links {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 80%;
    background-color: #2d3748; /* Same as navbar background */
    flex-direction: column;
    justify-content: center; /* Vertically center the items */
    align-items: center;   /* Horizontally center the items */
    gap: 20px;             /* Good spacing between items */
    padding: 0;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%); /* Hide sidebar by default */
  }
  
  /* When the sidebar is open */
  .nav-links.show {
    transform: translateX(0);
  }
  
  /* Adjust navigation link styling for mobile */
  .nav-link {
    display: block;
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }
}
